import { Button, Icon, LinkBox, LinkOverlay, Show, Td, Tr, Text, Tag } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { isPast } from 'date-fns'; // Importing date-fns for date comparison
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import ViewIcon from 'assets/view_icon.svg?component'
import getOrder from 'azure/order/getOrder'
import { ResponsiveTableContext } from 'components/elements/ResponsiveTable/Provider/context'
import SectionLoading from 'components/elements/SectionLoading';
import OrderStatus from 'components/modules/Order/OrderDetails/OrderStatus'
import { RoutePaths } from 'config/routes'
import assignParamsToUrl from 'utils/assignParamsToUrl'
import formatDate from 'utils/formatDate'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

interface OrderHistoryTableRowProps extends OrderHeader { }

const OrderHistoryTableRow: FunctionComponent<OrderHistoryTableRowProps> = ({
  orderNumber,
  customerOrderNumber,
  orderDate,
  status,
}) => {
  const { lang, t } = useTranslation('orders')
  const headings = useContext(ResponsiveTableContext)

  // Fetch the erpCustomerNumber
  const businessUnit = useBusinessUnit()
  const erpCustomerNumber = businessUnit?.data?.current?.custom?.fields?.erpCustomerNumber

  const shouldFetchPaymentStatus = Number(status) >= 77; // Only fetch if invoiced

  const { data: orderDetails, isLoading, error } = useQuery(
    ['getOrder', orderNumber, erpCustomerNumber],
    getOrder,
    {
      enabled: shouldFetchPaymentStatus && !!orderNumber && !!erpCustomerNumber,
      refetchOnWindowFocus: false,
    }
  );

  const paymentStatus = shouldFetchPaymentStatus && orderDetails?.orderDetails?.invoices?.[0]
    ? orderDetails.orderDetails.invoices[0]
    : null;
  // const isDatePast = (dateString: string, paid: boolean): boolean => isPast(new Date(dateString)) && !paid;

  const confirmedDeliveryDate = orderDetails?.orderItems?.length ?
    orderDetails.orderItems[0].confirmedDeliveryDate ?? undefined : undefined;

  return (
    <LinkBox as={Tr} data-link="true">
      <Td data-label={headings[0].title} maxW={{ md: '250' }} wordBreak="break-word" whiteSpace="normal">
        <LinkOverlay as={NextLink} href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}>
          {orderNumber}
        </LinkOverlay>
      </Td>
      <Td data-label={headings[1].title}>{customerOrderNumber}</Td>
      <Td data-label={headings[2].title}>{formatDate(orderDate, lang)}</Td>
      <Td data-label={headings[2].title}>{formatDate(confirmedDeliveryDate, lang)}</Td>
      <Td data-label={headings[3].title}>
        <OrderStatus status={status} />
      </Td>

      {/* Display payment status */}
      <Td data-label="Payment Status">
        {shouldFetchPaymentStatus ? (
          isLoading ? (
            <SectionLoading />
          ) : error ? (
            <>
              {(error as any)?.status === 404 ? (
                <Text color="yellow.500">{t('pages.order.orderItemDetails.notAvailable')}</Text>
              ) : (
                <Text color="red.500">{t('pages.order.orderItemDetails.errorFetching')}</Text>
              )}
            </>
          ) : paymentStatus ? (
            <>
              <Text as="span" role="status"
                color={isPast(new Date(paymentStatus.dueDate)) && !paymentStatus.isPaid ? 'red.500' : 'gray.800'}>
                {t('pages.order.orderItemDetails.due')} : {formatDate(paymentStatus.dueDate, lang)}
              </Text>
              <Tag
                ml={2}
                colorScheme={paymentStatus.isPaid ? "green" : "yellow"}
                variant="solid"
                borderRadius="full"
              >
                {paymentStatus.isPaid ? t('pages.order.orderItemDetails.paid') : t('pages.order.orderItemDetails.due')}
              </Tag>
            </>
          ) : null
        ) : null}
      </Td>

      <Show below="sm">
        <Td justifyContent="center" mt="4">
          <Button
            w="full"
            leftIcon={<Icon aria-hidden="true" as={ViewIcon} boxSize={4} color="primary.blue" />}
            href={assignParamsToUrl(RoutePaths.order, { slug: orderNumber })}
            as={NextLink}
          >
            {t('pages.order.table.view')}
          </Button>
        </Td>
      </Show>
    </LinkBox>
  )
}

OrderHistoryTableRow.displayName = 'OrderHistoryTableRow'

export default OrderHistoryTableRow
