export const OrderStatuses = {
  Quotation: '05',
  Preliminary: '10',
  NoOrderLines: '20',
  Reserved: '22',
  ReservedAllocated: '23',
  ReservedPickingList: '24',
  ReservedDelivery: '26',
  ReservedInvoicing: '27',
  ReservedCompleted: '29',
  Allocated: '33',
  AllocatedPickingList: '34',
  AllocatedDelivery: '36',
  AllocatedInvoicing: '37',
  AllocatedCompleted: '39',
  PickListPrinted: '44',
  PickListDelivery: '46',
  PickListInvoicing: '47',
  PickListCompleted: '49',
  Delivered: '66',
  DeliveredInvoicing: '67',
  DeliveredCompleted: '69',
  Invoiced: '77',
  InvoicingCompleted: '79',
  Deleted: '90',
  Completed: '99',
} as const satisfies Record<string, string>

export const OrderStatusesSteps = {
  Preliminary: ['05', '10', '20'], // Quotation, Preliminary, Not Completed
  Reserved: ['22', '23', '24', '26', '27', '29'], // All reserved statuses
  Allocated: ['33', '34', '36', '37', '39'], // All allocated statuses
  ReadyForDelivery: ['44', '46', '47', '49'], // Pick List Printed & Picked for Delivery
  Shipped: ['66', '67', '69'], // Delivered, Delivered/Invoicing, Delivered/Completed
  Completed: ['77', '79', '90', '99'], // Invoiced, Invoicing Completed, Deleted, Completed
} as const satisfies Record<string, readonly string[]>

export const OrderStatusFilters = {
  Reserved: '22',
  Completed: '99',
} as const satisfies Record<string, string>

// 🔹 TypeScript Definitions (UNCHANGED)
export type OrderStatusesKeys = keyof typeof OrderStatuses
export type OrderStatusesValues = (typeof OrderStatuses)[OrderStatusesKeys]

export type OrderStatusesFlattenValues = (typeof OrderStatuses)[OrderStatusesKeys] extends infer Element
  ? Element extends ReadonlyArray<infer ElementType>
    ? ElementType
    : Element
  : never

export const OrderStatusesColor = {
  [OrderStatuses.Quotation]: 'primary.red',
  [OrderStatuses.Preliminary]: 'primary.red',
  [OrderStatuses.NoOrderLines]: 'primary.red',
  [OrderStatuses.Reserved]: 'primary.black',
  [OrderStatuses.ReservedAllocated]: 'primary.black',
  [OrderStatuses.ReservedPickingList]: 'primary.black',
  [OrderStatuses.ReservedDelivery]: 'primary.black',
  [OrderStatuses.ReservedInvoicing]: 'primary.black',
  [OrderStatuses.ReservedCompleted]: 'primary.black',
  [OrderStatuses.Allocated]: 'gray.600',
  [OrderStatuses.AllocatedPickingList]: 'gray.600',
  [OrderStatuses.AllocatedDelivery]: 'gray.600',
  [OrderStatuses.AllocatedInvoicing]: 'gray.600',
  [OrderStatuses.AllocatedCompleted]: 'gray.600',
  [OrderStatuses.PickListPrinted]: 'primary.yellow',
  [OrderStatuses.PickListDelivery]: 'primary.yellow',
  [OrderStatuses.PickListInvoicing]: 'primary.yellow',
  [OrderStatuses.PickListCompleted]: 'primary.yellow',
  [OrderStatuses.Delivered]: 'primary.lightBlue',
  [OrderStatuses.DeliveredInvoicing]: 'primary.lightBlue',
  [OrderStatuses.DeliveredCompleted]: 'primary.lightBlue',
  [OrderStatuses.Invoiced]: 'primary.willowGreen',
  [OrderStatuses.InvoicingCompleted]: 'primary.lightBlue',
  [OrderStatuses.Completed]: 'primary.willowGreen',
  [OrderStatuses.Deleted]: 'gray.400',
} as const satisfies Record<OrderStatusesFlattenValues, string>
