import type { QueryFunctionContext } from '@tanstack/react-query'

import { fetchWithAzureToken } from 'azure/client/user'
import { AzureScopes } from 'config/auth'
import { RoutePaths } from 'config/routes'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'
/**
 * @param {string} erpCustomerNumber - Erp customer number from business unit custom fields
 * @param {string} orderNumber - Order number from order detail
 * @returns order detail from azure
 */

type QueryKey = [queryFnName: string, orderNumber: string, erpCustomerNumber?: string]

type OrderList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getAzureOrder({ queryKey: [, orderNumber, erpCustomerNumber] }: OrderList): Promise<Order> {
  if (!orderNumber || !erpCustomerNumber) {
    return Promise.reject(new Error('orderNumber or erpCustomerNumber is missing'))
  }

  const params = new URLSearchParams({ orderNumber, erpCustomerNumber }).toString()

  try {
    const response = await fetchWithAzureToken(AzureScopes.Orders, `${RoutePaths.ordersApi}?${params}`)

    if (!response.ok) {
      const error = new Error(`Request failed with status ${response.status}`)
      ;(error as any).status = response.status // Attach status to error
      throw error
    }

    return await jsonOrEmptyTextMapper(response)
  } catch (error) {
    throw error // Ensure React Query recognizes the error
  }
}

export default getAzureOrder
